import { graphql } from 'gatsby';
import { Location } from 'history';
import React from 'react';
import DrivingOffencesBaseComponent, {
  DrivingOffencesData,
} from 'components/FormPage/drivingOffences';
import { DriverType } from 'types/forms';

type DrivingOffencesProps = {
  data: {
    csAddDriverDrivingOffencesTemplate: DrivingOffencesData;
  };
  location: Location;
};

const DrivingOffencesPage: React.FC<DrivingOffencesProps> = ({ data, location }) => (
  <DrivingOffencesBaseComponent
    data={data.csAddDriverDrivingOffencesTemplate}
    location={location}
    driverType={DriverType.TEMPORARY}
  />
);

export default DrivingOffencesPage;

export const query = graphql`
  query {
    csAddDriverDrivingOffencesTemplate(driver_type: { eq: "Temporary" }) {
      meta_title
      heading
    }
  }
`;
